import React, { useContext, useState } from 'react';
import axios from 'axios';
import { getTranslation, Language } from '../../language';
import { Loader } from '../Loader';
import { ExitBtn } from '../../c_ui/ExitBtn';
import { Input } from '../../c_ui/Input/Input';
import { Btn } from '../../c_ui/Btn';
import style from './ResearcherModal.module.scss'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const ResearcherModal = ({ data, exitHandle, userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [file, setFile] = useState(null)
	const [loader, setLoader] = useState(false)

	const handleUpload = async event => {
		event.preventDefault()

		if (!file) {
			alert(TEXT.processor_alert)
			return
		}

		const formData = new FormData()
		formData.append('id', data._id)
		formData.append('date', data.post_date)
		formData.append('resource', data.resource)
		formData.append('researcher_name', userName)
		Array.from(file).forEach(file => {
			formData.append('file', file)
		})

		console.log(userName)

		try {
			setLoader(true)

			// завантаження файлу на сервер
			await axios.post(`${BASE_URL}/uploadLargeFile`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} catch (err) {
			console.log(err)
		} finally {
			setLoader(false)
			exitHandle(false)
			document.body.style.overflow = ''
		}
	}

	return (
		<div className={style.area}>
			<form onSubmit={handleUpload} className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div>
					<p className={style.text}>
						{TEXT.researcher_modal_recource}: @{data.resource}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_date}: {data.post_date.replaceAll('_', '.')}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_file_name}: {data.file_name}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_message_body}: {data.message_content}
					</p>
				</div>

				<Input inputType={'file_one'} setInputValue={setFile} />

				<Btn type='submit' text={TEXT.researcher_modal_btn_upload} />
			</form>

			{loader && <Loader />}
		</div>
	)
}
