import React from 'react';
import style from './Btn.module.scss'
import classNames from 'classnames';

export const Btn = ({
	text,
	handle = ()=>{},
	type = 'button',
	isActive,
}) => {
	return (
		<button type={type} className={classNames({
			[style.btn]: true,
			[style.isActive]: isActive,
		})} onClick={handle}>
			{text}
		</button>
	)
}
