import React from 'react'
import style from './IgnoreIcon.module.scss'

export const IgnoreIcon = () => {
	return (
		<svg viewBox='0 0 256 256' className={style.done}>
			<g
				className={style.done__G}
				transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
			>
				<path
					d='M 5 90 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 80 -80 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 l -80 80 C 7.559 89.512 6.28 90 5 90 z'
					className={style.done__P}
					transform=' matrix(1 0 0 1 0 0) '
					stroke-linecap='round'
				/>
				<path
					d='M 85 90 c -1.279 0 -2.56 -0.488 -3.535 -1.465 l -80 -80 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 80 80 c 1.953 1.952 1.953 5.118 0 7.07 C 87.56 89.512 86.279 90 85 90 z'
					className={style.done__P}
					transform=' matrix(1 0 0 1 0 0) '
					stroke-linecap='round'
				/>
			</g>
		</svg>
	)
}
