import React, { useContext, useEffect, useState } from 'react'
import style from './CheckOption.module.scss'
import { getTranslation, Language } from '../../language'
import classNames from 'classnames'
import axios from 'axios'
import logo from './logo32.png'
import { Input } from '../../c_ui/Input/Input'
import { ScrollUpBtn } from '../../c_ui/ScrollUpBtn/ScrollUpBtn'
import { UpDownBtn } from '../../c_ui/UpDownBtn'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { ResearcherModal } from '../ResearcherModal'
import { RefreshBtn } from '../../c_ui/RefreshBtn'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { useNavigate } from 'react-router-dom'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const CheckOption = ({ userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [date, setDate] = useState('')
	const [filesData, setFilesData] = useState([])
	const [selectedOption, setSelectedOption] = useState('')
	const [modalInfo, setModalInfo] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [refresh, setRefresh] = useState(true)

	const headers = [
		TEXT.researcher_check_table_name,
		TEXT.researcher_check_table_resource,
		TEXT.researcher_check_table_size,
		<UpDownBtn type={'head'} />,
		TEXT.researcher_check_table_comment,
	]

	useEffect(() => {
		const getInfo = async () => {
			if (date && selectedOption) {
				await fetchInfo(date, selectedOption)
			}
		}

		getInfo()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}
	}, [date, selectedOption, modalInfo, refresh])

	const fetchInfo = async (currentDate, currentOption) => {
		const processedDate = currentDate.replaceAll('-', '_')
		
		try {
			const info = await axios.post(`${BASE_URL}/getUploadFiles`, {
				targetDate: processedDate,
				userName: currentOption,
			})

			setFilesData(() => info.data)
			if (errorMessage && info.data.length) {
				setErrorMessage('')
			}

			if (!info.data.length) {
				setErrorMessage('Цього дня не було завантажень')
			}
		} catch (error) {
			if (error.status === 404) {
				console.log('fetchInfo func error', error)
			}
		}
	}

	const handleManualDownload = (data) => {
		setModalInfo(data)
		document.body.style.overflow = 'hidden'
	}

	return (
		<div className={style.check}>
			<span className={style.row}>
				<p className={style.text}>{TEXT.researcher_check_date}</p>

				<Input inputType={'date'} inputValue={date} setInputValue={setDate} />
			</span>

			<span className={style.row}>
				<p className={style.text}>{TEXT.researcher_check_who_collected}</p>

				<span className={style.options}>
					<label
						className={classNames({
							[style.radiolable]: true,
							[style.radiolable__isActive]: selectedOption === userName,
						})}
					>
						{userName}
						<Input
							inputType={'radio'}
							inputValue={userName}
							selectedValue={selectedOption}
							setInputValue={setSelectedOption}
						/>
					</label>

					<label
						className={classNames({
							[style.radiolable]: true,
							[style.radiolable__isActive]: selectedOption === 'Hamster',
						})}
					>
						<img src={logo} alt='logo' className={style.hamster} />
						<Input
							inputType={'radio'}
							inputValue={'Hamster'}
							selectedValue={selectedOption}
							setInputValue={setSelectedOption}
						/>
					</label>
				</span>
			</span>

			{filesData && (
				<span className={style.row}>
					<p className={style.text}>
						{TEXT.researcher_check_totally_uploaded} - {filesData.length}
					</p>

					<RefreshBtn handle={setRefresh} />
				</span>
			)}

			<table>
				<thead>
					<tr>
						{headers.map((header, headerIndex) => (
							<th key={headerIndex} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{filesData.map((fileData, fileDataIndex) => (
						<tr key={fileDataIndex}>
							
							{/* Name */}
							{fileData.file_name ? (
								<td title={fileData.file_name} className={style.td__1}>
									{fileData.file_name}
								</td>
							) : (
								<td className={style.td__1}></td>
							)}
							
							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td__2}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td__2}></td>
							)}
							
							{/* Size */}
							{fileData.file_size ? (
								<td title={fileData.file_size} className={style.td__3}>
									{fileData.file_size}
								</td>
							) : (
								<td className={style.td__3}></td>
							)}
							
							{/* UpDownBtn */}
							{fileData.is_uploaded ? (
								<td title={userName} className={style.td__4}>
									<DoneIcon />
								</td>
							) : (
								<td title={userName} className={style.td__4}>
									<UpDownBtn
										type={'researcher'}
										data={fileData}
										handle={() => handleManualDownload(fileData)}
									/>
								</td>
							)}
							
							{/* Comment */}
							{fileData.comment ? (
								<td title={fileData.comment} className={style.td__5}>
									{fileData.comment}
								</td>
							) : (
								<td className={style.td__5}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<p className={style.errorText}>{errorMessage}</p>

			{filesData.length > 20 && <ScrollUpBtn />}

			{modalInfo && (
				<ResearcherModal
					data={modalInfo}
					exitHandle={setModalInfo}
					userName={'123'}
				/>
			)}
		</div>
	)
}
