import React, { useContext, useEffect, useState } from 'react'
import style from './Inspector.module.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Wellcome } from '../../components/Wellcome'
import { UserAdd } from '../../components/UserAdd'
import { Btn } from '../../c_ui/Btn'
import { getTranslation, Language } from '../../language'
import { ResearchersOption } from '../../components/ResearchersOption'
import { ProcessorsOption } from '../../components/ProcessorsOption'
import { DeleteBtn } from '../../c_ui/DeleteBtn'
import { Input } from '../../c_ui/Input/Input'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'

const BASE_URL = process.env.REACT_APP_BASE_URL

const roles = {
	researcher: 'researcher',
	processor: 'processor',
	inspector: 'inspector',
}

export const Inspector = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [userName, setUserName] = useState('')
	const [users, setUsers] = useState([])
	const [user, setUser] = useState('')
	const [date, setDate] = useState('')
	const [role, setRole] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		async function fetchData() {
			const storedUserId = localStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserName(user.data.name)
				}
			} catch (err) {
				console.log('fetchData func error')
				navigate('/')
			}
		}

		fetchData()
	}, [])

	// useEffect(() => {
	// 	if (user && date) {
	// 		console.log(user, date)
	// 	}

	// 	const autorizationData = autorizationAnalizer()
	// 	if (!autorizationData) {
	// 		navigate('/')
	// 	}
	// }, [user, date])
	
	const fetchUsers = async (neededRole = role) => {
		try {
			const users = await axios.post(`${BASE_URL}/getUsers`, {
				userRole: neededRole,
			})

			setUsers(users.data)
		} catch (error) {
			console.log('fetchUsers func error', error)
		}
	}

	const handlerResearchers = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (user || date) {
			setUser('')
			setDate('')
		}
		setRole(roles.researcher)
		fetchUsers(roles.researcher)
	}

	const handlerProcessors = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (user || date) {
			setUser('')
			setDate('')
		}
		setRole(roles.processor)
		fetchUsers(roles.processor)
	}

	const handlerInspectors = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (user || date) {
			setUser('')
			setDate('')
		}
		setRole(roles.inspector)
		fetchUsers(roles.inspector)
	}

	const handlerDeleteUser = async deleteUserId => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		try {
			await axios.post(`${BASE_URL}/deleteUser`, {
				userId: deleteUserId,
			})

			await fetchUsers()
		} catch (error) {
			console.log('deleteUser error', error)
		}
	}

	const handleAddUser = async (name, password, passwordRepeat) => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (password === passwordRepeat) {
			try {
				const user = await axios.post(`${BASE_URL}/getUserByName`, {
					userName: name,
					userRole: role,
				})

				if (user.status === 200) {
					setErrorMessage('юзер з таким імʼям вже є')
				}
			} catch (e) {
				try {
					await axios.post(`${BASE_URL}/addUser`, {
						userName: name,
						userPassword: password,
						userRole: role,
					})

					if (errorMessage) {
						setErrorMessage('')
					}
					await fetchUsers()
				} catch (error) {
					console.log('addUser error', error)
				}
			}
		} else {
			setErrorMessage('пароль не однаковий')
		}
	}

	const handleChooseUser = async event => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		setUser(event.target.value)
	}

	return (
		<div className={style.inspector}>
			<Wellcome userName={userName} />

			<div className={style.buttons}>
				<Btn
					text={TEXT.inspector_btn_researchers}
					handle={handlerResearchers}
					isActive={role === roles.researcher}
				/>
				<Btn
					text={TEXT.inspector_btn_processors}
					handle={handlerProcessors}
					isActive={role === roles.processor}
				/>
				<Btn
					text={TEXT.inspector_btn_inspectors}
					handle={handlerInspectors}
					isActive={role === roles.inspector}
				/>
			</div>

			{role && (
				<div>
					<table>
						<thead>
							<tr>
								<th className={style.th}>
									{role === roles.researcher
										? TEXT.inspector_btn_researchers
										: role === roles.processor
										? TEXT.inspector_btn_processors
										: TEXT.inspector_btn_inspectors}
								</th>
							</tr>
						</thead>
						<tbody>
							{users.length > 0 &&
								users.map((user, i) => (
									<tr key={i}>
										<td className={style.td}>
											<span className={style.td__wrapper}>
												{user.name}

												{user.name !== userName && (
													<DeleteBtn
														handle={handlerDeleteUser}
														value={user._id}
													/>
												)}
											</span>
										</td>
									</tr>
								))}
							{role === roles.researcher && (
								<tr>
									<td className={style.td}>Hamster</td>
								</tr>
							)}
						</tbody>
					</table>

					<UserAdd handle={handleAddUser} />

					{(role === roles.researcher || role === roles.processor) && (
						<>
							<span className={style.row}>
								<span>
									<p className={style.text}>
										{role === roles.researcher
											? TEXT.inspector_choose_researcher
											: TEXT.inspector_choose_processor}
									</p>

									<select
										value={user}
										onChange={handleChooseUser}
										className={style.select}
									>
										<option value='' selected disabled>
											{TEXT.inspector_make_choose}
										</option>
										{users.map((user, i) => (
											<option key={i} value={user.name}>
												{user.name}
											</option>
										))}

										{role === roles.researcher && (
											<option value='Hamster'>{'Hamster'}</option>
										)}

										<option value='all'>{TEXT.inspector_choose_all}</option>
									</select>
								</span>

								{user && (
									<span>
										<p className={style.text}>{TEXT.inspector_choose_date}</p>

										<Input
											inputType={'date'}
											inputValue={date}
											setInputValue={setDate}
										/>
									</span>
								)}
							</span>

							{role === roles.researcher && date && (
								<ResearchersOption name={user} date={date} role={role} />
							)}
							{role === roles.processor && date && (
								<ProcessorsOption name={user} date={date} role={role} />
							)}
						</>
					)}
				</div>
			)}

			{!role && <p className={style.choice}>{TEXT.choice}</p>}

			{errorMessage && <p>{errorMessage}</p>}
		</div>
	)
}
