import React, { useContext, useEffect, useState } from 'react';
import style from './ProcessorsOption.module.scss'
import { getTranslation, Language } from '../../language';
import axios from 'axios';
import { UpDownBtn } from '../../c_ui/UpDownBtn';
import { InspectorModal } from '../InspectorModal';
import autorizationAnalizer from '../../service/functions/autorizationAnalizer';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const ProcessorsOption = ({ name, date, role }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [filesData, setFilesData] = useState([])
	const [modalInfo, setModalInfo] = useState()

	const headers = [
		TEXT.inspector_table_resource,
		TEXT.inspector_table_unfiltered_files_count,
		TEXT.inspector_table_filtered_files_count,
		TEXT.inspector_table_filtered_rows_count,
	]

	useEffect(() => {
		async function fetchUserInfo() {
			try {
				const info = await axios.post(`${BASE_URL}/getUserInfo`, {
					userName: name,
					neededDate: date.replaceAll('-', '_'),
					userRole: role,
				})

				if (info) {
					const filteredData = []
					const resources = [...new Set(info.data.map(file => file.resource))]

					for (const resource of resources) {
						const filteredFiles = info.data.filter(
							file => file.resource === resource && file.is_filtered
						)
						const unFilteredFiles = info.data.filter(
							file => file.resource === resource && !file.is_filtered
						)
						const totalFilteredRowsCount = filteredFiles.reduce(
							(acc, file) => acc + file.processed_rows,
							0
						)

						filteredData.push({
							resource: resource,
							filtered_count: filteredFiles.length,
							unfiltered_count: unFilteredFiles.length,
							processed_rows: totalFilteredRowsCount,
							all_filtered_data: filteredFiles,
							all_unfiltered_data: unFilteredFiles,
						})
					}

					setFilesData(filteredData)
				}
			} catch (err) {
				console.log('fetchUserInfo func error')
			}
		}

		fetchUserInfo()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}
	}, [name, date])

	const handleManualDownload = data => {
		console.log(data)
		setModalInfo(data)
		document.body.style.overflow = 'hidden'
	}

	return (
		<>
			<table>
				<thead>
					<tr>
						{headers.map((header, i) => (
							<th key={i} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					{filesData.map((fileData, i) => (
						<tr key={i}>
							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td__1}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td__1}></td>
							)}

							{/* UNfiltered_count */}
							{fileData.unfiltered_count ? (
								<td title={fileData.resource} className={style.td__2}>
									<span className={style.td__2__wrap}>
										{fileData.unfiltered_count}

										<UpDownBtn
											data={fileData.all_unfiltered_data}
											handle={() =>
												handleManualDownload(fileData.all_unfiltered_data)
											}
										/>
									</span>
								</td>
							) : (
								<td className={style.td__2}></td>
							)}

							{/* filtered_count */}
							{fileData.filtered_count ? (
								<td title={fileData.resource} className={style.td__3}>
									<span className={style.td__3__wrap}>
										{fileData.filtered_count}

										<UpDownBtn
											data={fileData.all_filtered_data}
											handle={() =>
												handleManualDownload(fileData.all_filtered_data)
											}
										/>
									</span>
								</td>
							) : (
								<td className={style.td__3}></td>
							)}

							{/* rows_count */}
							{fileData.processed_rows ? (
								<td title={fileData.resource} className={style.td__4}>
									{fileData.processed_rows}
								</td>
							) : (
								<td className={style.td__4}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			{modalInfo && (
				<InspectorModal
					data={modalInfo}
					exitHandle={setModalInfo}
				/>
			)}
		</>
	)
}
