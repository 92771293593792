import React from 'react'
import style from './DoneIcon.module.scss'

export const DoneIcon = () => {
	return (
		<svg viewBox='0 0 256 256' className={style.done}>
			<g
				className={style.done__G}
				transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
			>
				<path
					d='M 45 90 c -2.761 0 -5 -2.238 -5 -5 V 5 c 0 -2.761 2.239 -5 5 -5 c 2.762 0 5 2.239 5 5 v 80 C 50 87.762 47.762 90 45 90 z'
					className={style.done__P}
					transform=' matrix(1 0 0 1 0 0) '
					stroke-linecap='round'
				/>
				<path
					d='M 85 50 H 5 c -2.761 0 -5 -2.238 -5 -5 c 0 -2.761 2.239 -5 5 -5 h 80 c 2.762 0 5 2.239 5 5 C 90 47.762 87.762 50 85 50 z'
					className={style.done__P}
					transform=' matrix(1 0 0 1 0 0) '
					stroke-linecap='round'
				/>
			</g>
		</svg>
	)
}
