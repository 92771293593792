import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import style from './Test.module.scss'
import { InspectorModal } from '../../components/InspectorModal'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const Test = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()

	const [test, setTest] = useState('')

	useEffect(() => {}, [])

	const handle = event => {
		event.preventDefault()
	}

	const data = {
		file_name: "application_3072.00.octet-stream",
		file_size: "3072.00 Mb",
		is_filtered: false,
		is_uploaded: false,
		message_content: "",
		post_date: "2024_11_01",
		researcher: "Hamster",
		resource: "free_logs_cloud4",
		uploaded_file_date: "2024_11_01",
		_id: "67370ec1b279906180c707be",
	}

	return (
		<div className={style.test}>
			<InspectorModal />
		</div>
	)
}
