import React, { useState, useContext } from 'react'
import { getTranslation, Language } from '../../language'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { IgnoreIcon } from '../../c_ui/IgnoreIcon'
import { Btn } from '../../c_ui/Btn'
import style from './UserAdd.module.scss'
import { Input } from '../../c_ui/Input/Input'

export const UserAdd = ({ handle }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [newUserName, setNewUserName] = useState('')
	const [newUserPassword, setNewUserPassword] = useState('')
	const [newUserPasswordRepeat, setNewUserPasswordRepeat] = useState('')

  const handleAddUser = event => {
    event.preventDefault()

    handle(newUserName, newUserPassword, newUserPasswordRepeat)

    setNewUserName('')
		setNewUserPassword('')
		setNewUserPasswordRepeat('')
  }

	return (
		<form onSubmit={handleAddUser} className={style.add}>
			<span className={style.inputs}>
				<Input
					inputType={'text'}
					inputPlaceholder={TEXT.inspector_new_login_placeholder}
					inputValue={newUserName}
					setInputValue={setNewUserName}
				/>

				<span className={style.inputs__wrap}>
					<span className={style.inputs__wrap__2}>
						<Input
							inputType={'password'}
							inputPlaceholder={TEXT.inspector_new_password_placeholder}
							inputValue={newUserPassword}
							setInputValue={setNewUserPassword}
						/>

						{newUserPassword && (
							<Input
								inputType={'password'}
								inputPlaceholder={
									TEXT.inspector_new_repeat_password_placeholder
								}
								inputValue={newUserPasswordRepeat}
								setInputValue={setNewUserPasswordRepeat}
							/>
						)}
					</span>

					{newUserPasswordRepeat.length > 0 &&
						(newUserPassword === newUserPasswordRepeat ? (
							<DoneIcon />
						) : (
							<IgnoreIcon />
						))}
				</span>
			</span>

			<Btn type='submit' text={TEXT.researcher_tg_btn_add} />
		</form>
	)
}
