import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { Wellcome } from '../../components/Wellcome'
import { ProcessorModal } from '../../components/ProcessorModal'
import { Input } from '../../c_ui/Input/Input'
import { UpDownBtn } from '../../c_ui/UpDownBtn'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { IgnoreIcon } from '../../c_ui/IgnoreIcon'
import { RefreshBtn } from '../../c_ui/RefreshBtn'
import style from './Processor.module.scss'
import { ScrollUpBtn } from '../../c_ui/ScrollUpBtn/ScrollUpBtn'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const Processor = () => {
	const navigate = useNavigate()
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [userName, setUserName] = useState('')
	const [date, setDate] = useState('')
	const [filesData, setFilesData] = useState([])
	const [modal, setModal] = useState(false)
	const [fileData, setFileData] = useState(null)
	const [refresh, setRefresh] = useState(true)

	const [errorMessage, setErrorMessage] = useState('')

	const headers = [
		TEXT.processor_table_name,
		TEXT.processor_table_resource,
		<UpDownBtn type={'head'} />,
		TEXT.processor_table_researcher,
		TEXT.processor_table_size,
		TEXT.processor_table_comment,
	]

	useEffect(() => {
		// const autorizationData = autorizationAnalizer()

		// if (autorizationData) {
		// 	// navigate(userData.role)
		// 	setUserName(autorizationData.name)
		// } else {
		// 	navigate('/')
		// }
		async function fetchData() {
			const storedUserId = localStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserName(user.data.name)
				}
			} catch (err) {
				console.log('fetchData func error')
				navigate('/')
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		async function getAllFiles() {
			// const [year, month, day] = date.split('-')
			const processedDate = date.replaceAll('-', '_')

			try {
				const files = await axios.post(`${BASE_URL}/getAllUploadFiles`, {
					// targetDate: month + '/' + day + '/' + year,
					targetDate: processedDate,
				})

				if (files) {
					setFilesData(files.data)
				}
			} catch (err) {
				console.log('getAllFiles func error')
			}
		}

		if (date) {
			getAllFiles()
		}

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}
	}, [date, modal, refresh])

	const handleUpDownload = data => {
		document.body.style.overflow = 'hidden'
		setFileData(data)
		setModal(true)
	}

	return (
		<div className={style.processor}>
			<Wellcome userName={userName} />

			<span className={style.row}>
				<p className={style.text}>{TEXT.researcher_check_date}</p>

				<Input inputType={'date'} inputValue={date} setInputValue={setDate} />
			</span>

			{filesData && (
				<span className={style.row}>
					<p className={style.text}>
						{TEXT.researcher_check_totally_uploaded} - {filesData.length}
					</p>

					<RefreshBtn handle={setRefresh} />
				</span>
			)}

			<table>
				<thead>
					<tr>
						{headers.map((header, headerIndex) => (
							<th key={headerIndex} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{filesData.map((fileData, fileDataIndex) => (
						<tr key={fileDataIndex}>
							{/* Name */}
							{fileData.file_name ? (
								<td title={fileData.file_name} className={style.td__1}>
									{fileData.file_name}
								</td>
							) : (
								<td className={style.td__1}></td>
							)}
							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td__2}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td__2}></td>
							)}
							{/* UpDownBtn */}
							{!fileData.is_filtered ? (
								!fileData.is_ignored ? (
									<td
										title={
											fileData.is_uploaded
												? TEXT.processor_table_icon_up_down_load_title_ready
												: TEXT.processor_table_icon_up_down_load_title_notReady
										}
										className={style.td__3}
									>
										<UpDownBtn
											type={'processor'}
											data={fileData}
											handle={() => handleUpDownload(fileData)}
										/>
									</td>
								) : (
									<td
										title={TEXT.processor_table_icon_ignore_title}
										className={style.td__3}
									>
										<IgnoreIcon />
									</td>
								)
							) : (
								<td
									title={TEXT.processor_table_icon_done_title}
									className={style.td__3}
								>
									<DoneIcon />
								</td>
							)}
							{/* Researcher */}
							{fileData.researcher ? (
								<td title={fileData.researcher} className={style.td__4}>
									{fileData.researcher}
								</td>
							) : (
								<td className={style.td__4}></td>
							)}
							{/* Size */}
							{fileData.file_size ? (
								<td title={fileData.file_size} className={style.td__5}>
									{fileData.file_size}
								</td>
							) : (
								<td className={style.td__5}></td>
							)}
							{/* Comment */}
							{fileData.ignore_comment ? (
								<td title={fileData.comment} className={style.td__6}>
									{fileData.ignore_comment}
								</td>
							) : fileData.processor_comment ? (
								<td title={fileData.comment} className={style.td__6}>
									{fileData.processor_comment}
								</td>
							) : (
								<td className={style.td__6}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<p className={style.errorText}>{errorMessage}</p>

			{modal && (
				<ProcessorModal
					data={fileData}
					exitHandle={setModal}
					userName={userName}
				/>
			)}

			{filesData.length > 20 && <ScrollUpBtn />}
		</div>
	)
}
