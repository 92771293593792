import axios from 'axios'

function autorizationAnalizer() {
	if (localStorage.length) {
		const storedTime = Number(localStorage.getItem('time'))
		const currentTime = new Date().getTime()

		if (currentTime - storedTime > 3600000 * 6) {
			localStorage.clear()
			return false
		} else {
			return true;
		}
	}
}

export default autorizationAnalizer
