import React, { useContext, useState } from 'react';
import axios from 'axios';
import { getTranslation, Language } from '../../language';
import { Loader } from '../Loader';
import { ExitBtn } from '../../c_ui/ExitBtn';
import style from './InspectorModal.module.scss'
import { DownloadBtn } from '../../c_ui/DownloadBtn'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const InspectorModal = ({ data = [], exitHandle }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [usedIds, setUsedIds] = useState([])
	const [loader, setLoader] = useState(false)

	const headers = ['name', 'size', <DownloadBtn type={'head'}/>]

	const handleDownload = async (currentData) => {
		// console.log(currentData)
		try {
			setLoader(true)
			const response = await axios.get(
				`${BASE_URL}/downloadFile/${currentData.post_date}/${currentData.resource}/${currentData.file_name}`,
				{ responseType: 'blob' }
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url

			link.setAttribute('download', currentData.file_name)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			
			if (data.length === 1) {
				exitHandle(false)
				document.body.style.overflow = ''
			} else {
				setLoader(false)
				setUsedIds(prevIds => [...prevIds, currentData._id])
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div className={style.tableContainer}>
					<table>
						<thead>
							<tr>
								{headers.map((header, i) => (
									<th key={i} className={style.th}>
										{header}
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{data.map((file, i) => (
								<tr key={i}>
									{/* Name */}
									{file.file_name ? (
										<td title={file.file_name} className={style.td__1}>
											{file.file_name}
										</td>
									) : (
										<td className={style.td__1}></td>
									)}

									{/* Size */}
									{file.file_size ? (
										<td title={file.file_size} className={style.td__2}>
											{file.file_size}
										</td>
									) : (
										<td className={style.td__2}></td>
									)}

									{/* Download */}
									<td title={'Download'} className={style.td__3}>
										<DownloadBtn type={usedIds.includes(file._id) ? 'downloaded' : ''} handle={() => handleDownload(file)} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* <form onSubmit={handleUpload} className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div>
					<p className={style.text}>
						{TEXT.researcher_modal_recource}: @{data.resource}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_date}: {data.post_date.replaceAll('_', '.')}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_file_name}: {data.file_name}
					</p>
					<p className={style.text}>
						{TEXT.researcher_modal_message_body}: {data.message_content}
					</p>
				</div>

				<Input inputType={'file'} setInputValue={setFile} />

				<Btn type='submit' text={TEXT.researcher_modal_btn_upload} />
			</form> */}

			{loader && <Loader />}
		</div>
	)
}
