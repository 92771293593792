import React, { useContext, useEffect, useState } from 'react';
import style from './ResearchersOption.module.scss'
import { getTranslation, Language } from '../../language';
import axios from 'axios';
import { InspectorModal } from '../InspectorModal';
import { UpDownBtn } from '../../c_ui/UpDownBtn';
import autorizationAnalizer from '../../service/functions/autorizationAnalizer';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const ResearchersOption = ({ name, date, role }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [filesData, setFilesData] = useState([])
	const [modalInfo, setModalInfo] = useState()

	const headers = [
		TEXT.inspector_table_resource,
		TEXT.inspector_table_files_count,
	]


	useEffect(() => {
		async function fetchUserInfo() {
			try {
				const info = await axios.post(`${BASE_URL}/getUserInfo`, {
					userName: name,
					neededDate: date.replaceAll('-', '_'),
					userRole: role,
				})

				if (info) {
					const filteredData = []
					const resources = [...new Set(info.data.map(file => file.resource))]

					for (const resource of resources) {
						const files = info.data.filter(file => file.resource === resource)
						
						filteredData.push({
							resource: resource,
							count: files.length,
							all_data: files,
						})
					}
					
					setFilesData(filteredData)
				}
			} catch (err) {
				console.log('fetchUserInfo func error')
			}
		}

		fetchUserInfo()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}
	}, [name, date])

	const handleManualDownload = data => {
		console.log(data)
		setModalInfo(data)
		document.body.style.overflow = 'hidden'
	}

	return (
		<>
			<table>
				<thead>
					<tr>
						{headers.map((header, i) => (
							<th key={i} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{filesData.map((fileData, i) => (
						<tr key={i}>
							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td__1}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td__1}></td>
							)}

							{/* Count */}
							{fileData.count ? (
								<td
									title={`${TEXT.inspector_table_files_count} - ${fileData.count}`}
									className={style.td__2}
								>
									<span className={style.td__2__wrap}>
										{fileData.count}

										<UpDownBtn
											data={fileData.all_data}
											handle={() =>
												handleManualDownload(fileData.all_data)
											}
										/>
									</span>
								</td>
							) : (
								<td className={style.td__2}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			{modalInfo && (
				<InspectorModal data={modalInfo} exitHandle={setModalInfo} />
			)}
		</>
	)
}
