import React, { useContext, useState } from 'react';
import style from './ProcessorModal.module.scss'
import { Btn } from '../../c_ui/Btn';
import { ExitBtn } from '../../c_ui/ExitBtn';
import axios from 'axios';
import { Input } from '../../c_ui/Input/Input';
import { getTranslation, Language } from '../../language';
import { Loader } from '../Loader';

const BASE_URL = process.env.REACT_APP_BASE_URL

const choose = {
	upload: 'upload',
	ignore: 'ignore',
}
export const ProcessorModal = ({ data, exitHandle, userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [isChoosed, setIsChoosed] = useState('')
	const [file, setFile] = useState(false)
	const [rowsCount, setRowsCount] = useState(null)
	const [comment, setComment] = useState('')
	const [loader, setLoader] = useState(false)

	const handleChooseDownload = async () => {
		try {
			const response = await axios.get(
				`${BASE_URL}/downloadFile/${data.post_date}/${data.resource}/${data.file_name}`,
				{ responseType: 'blob' }
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url

			link.setAttribute('download', data.file_name)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			exitHandle(false)
			document.body.style.overflow = ''
		} catch (err) {
			console.log(err)
		}
	}

	const handleChooseUpload = () => {
		setIsChoosed(choose.upload)
	}
	const handleChooseIgnore = () => {
		setIsChoosed(choose.ignore)
	}

	const handleUpload = async event => {
		event.preventDefault()

		if (!data.post_date || !rowsCount) {
			alert(TEXT.processor_alert)
			return
		}

		const formData = new FormData()
		formData.append('id', data._id)
		formData.append('date', data.post_date)
		formData.append('resource', data.resource)
		formData.append('processor_name', userName)
		formData.append('rows_count', rowsCount)
		formData.append('comment', comment)
		// formData.append('is_filtered', true)
		Array.from(file).forEach(file => {
			formData.append('file', file)
		})

		try {
			setLoader(true)

			// завантаження відфільтрованого файлу на сервер
			await axios.post(`${BASE_URL}/uploadFilteredFile`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		} catch (err) {
			console.log(err)
		} finally {
			setLoader(false)
			exitHandle(false)
			document.body.style.overflow = ''
		}
	}

	const handleIgnore = async event => {
		event.preventDefault()

		if (!comment) {
			alert(TEXT.processor_alert)
			return
		}

		await axios.post(`${BASE_URL}/ignoreFile`, {
			userId: data._id,
			comment: comment,
		})
		exitHandle(false)
		document.body.style.overflow = ''
	}

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				{!isChoosed && (
					<span className={style.btns}>
						<Btn
							text={TEXT.processor_modal_btn_download}
							handle={handleChooseDownload}
						/>
						<Btn
							text={TEXT.processor_modal_btn_upload}
							handle={handleChooseUpload}
						/>
						<Btn
							text={TEXT.processor_modal_btn_ignore}
							handle={handleChooseIgnore}
						/>
					</span>
				)}

				{isChoosed === choose.upload && (
					<form onSubmit={handleUpload} className={style.form}>
						<span className={style.row}>
							<p className={style.text}>{TEXT.processor_add_file}</p>

							<Input inputType={'file_one'} setInputValue={setFile} />
						</span>

						<span className={style.row}>
							<p className={style.text}>{TEXT.processor_modal_rows_count}</p>

							<Input
								inputType={'number'}
								inputPlaceholder={TEXT.processor_modal_rows_count_placeholder}
								inputValue={rowsCount}
								setInputValue={setRowsCount}
							/>
						</span>

						<span className={style.row}>
							<p className={style.text}>
								{TEXT.processor_modal_upload_comment}
							</p>

							<textarea
								value={comment}
								placeholder={TEXT.processor_modal_upload_comment_placeholder}
								onChange={e => setComment(e.target.value)}
								className={style.input}
							/>
						</span>

						<Btn type='submit' text={TEXT.processor_modal_btn_upload} />
					</form>
				)}

				{isChoosed === choose.ignore && (
					<form onSubmit={handleIgnore} className={style.form}>
						<span className={style.row}>
							<p className={style.text}>
								{TEXT.processor_modal_ignore_comment}
							</p>

							<textarea
								value={comment}
								placeholder={TEXT.processor_modal_ignore_comment_placeholder}
								onChange={e => setComment(e.target.value)}
								className={style.input}
							/>
						</span>

						<Btn type='submit' text={TEXT.processor_modal_btn_ignore} />
					</form>
				)}
			</div>

			{loader && <Loader />}
		</div>
	)
}
