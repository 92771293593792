import React, { useContext } from 'react';
import { getTranslation, Language } from '../../language'
import { ExitBtn } from '../../c_ui/ExitBtn'
import style from './Wellcome.module.scss'

export const Wellcome = ({ userName }) => {
  const lang = useContext(Language)
  const TEXT = getTranslation(lang)

	return (
		<span className={style.wellcome}>
			{TEXT.welcome} {userName} !
			<ExitBtn />
		</span>
	)
}
