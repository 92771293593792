import React from 'react';
import style from './TgCart.module.scss'

export const TgCart = () => {
	return (
		<svg
			version='1.1'
			viewBox='0 0 256 256'
			className={style.cart}
		>
			<g
				className={style.cart__G}
				transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
			>
				<polyline
					points='16.05,20.79 84.06,20.79 60.33,52.01 24.33,40.7 16.05,20.79 '
					className={style.cart__pol}
					transform=' matrix(1 0 0 1 0 0) '
				/>
				<path
					d='M 86.69 24.495 H 15.445 c -1.828 0 -3.31 -1.482 -3.31 -3.31 v 0 c 0 -1.828 1.482 -3.31 3.31 -3.31 H 86.69 c 1.828 0 3.31 1.482 3.31 3.31 v 0 C 90 23.013 88.518 24.495 86.69 24.495 z'
					className={style.cart__path}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<circle
					cx='76.598'
					cy='59.168'
					r='12.908'
					className={style.cart__c}
					transform=' matrix(1 0 0 1 0 0) '
				/>
				<path
					d='M 41.303 72.108 c -0.23 0.024 -0.464 0.024 -0.699 -0.003 c -1.137 -0.13 -2.115 -0.861 -2.563 -1.913 L 28.618 47.59 c -0.689 -1.616 0.063 -3.485 1.679 -4.174 c 1.616 -0.69 3.484 0.063 4.174 1.679 l 7.23 17.459 L 51.853 50.92 c 1.037 -1.418 3.028 -1.726 4.446 -0.689 c 1.418 1.037 1.726 3.028 0.689 4.446 L 43.534 70.823 C 42.998 71.555 42.183 72.014 41.303 72.108 z'
					className={style.cart__path}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<path
					d='M 74.838 61.959 L 2.32 38.837 c -1.744 -0.548 -2.714 -2.406 -2.167 -4.15 l 0 0 c 0.548 -1.744 2.406 -2.714 4.15 -2.167 l 72.518 23.122 c 1.744 0.548 2.714 2.406 2.167 4.15 v 0 C 78.44 61.536 76.582 62.506 74.838 61.959 z'
					className={style.cart__path}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
			</g>
		</svg>
	)
}
