import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './ExitBtn.module.scss'
import { getTranslation, Language } from '../../language';

export const ExitBtn = ({ exitPropHandle }) => {
	const navigate = useNavigate()
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)

	const handleExit = () => {
		exitPropHandle(false)
		document.body.style.overflow = ''
	}

	const handleExitNavigate = () => {
		localStorage.clear()
		navigate('/')
	}

	return (
		<button
			type='button'
			className={style.btn}
			onClick={exitPropHandle ? handleExit : handleExitNavigate}
			title={TEXT.btn_exit_title}
		>
			{TEXT.btn_exit}
		</button>
	)
}
