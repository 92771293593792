import React, { useContext, useState } from 'react'
import { Btn } from '../../c_ui/Btn'
import style from './AddOption.module.scss'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../components/Loader'
import { Input } from '../../c_ui/Input/Input'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const AddOption = ({ userName }) => {
  const navigate = useNavigate()
  const lang = useContext(Language)
  const TEXT = getTranslation(lang)
	const [date, setDate] = useState('')
	const [resource, setResource] = useState('')
	const [files, setFiles] = useState(null)
	const [comment, setComment] = useState('')
	const [loader, setLoader] = useState(false)

  const handleUpload = async event => {
		event.preventDefault()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			navigate('/')
		}

		if (!date || !resource || !files) {
			alert(TEXT.researcher_alert)
			return
		}

		const formData = new FormData()
		formData.append('date', date.replace(/-/g, '_'))
		formData.append('resource', resource)
		formData.append('researcher_name', userName)
		formData.append('comment', comment)
		// formData.append('is_filtered', false)
		Array.from(files).forEach(file => {
			formData.append('files', file)
		})

		try {
      setLoader(true)

      // завантаження файлів на сервер
			await axios.post(
				`${BASE_URL}/uploadFiles`,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' },
				}
			)

      navigate(0)
		} catch (error) {
      setLoader(false)
			console.error('Помилка завантаження:', error)
			alert('Помилка завантаження файлів')
		}
	}

	return (
		<div className={style.add}>
			<form onSubmit={handleUpload} className={style.form}>
				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_date}</p>

					<Input inputType={'date'} inputValue={date} setInputValue={setDate} />
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_resource}</p>

					<Input
						inputType={'text'}
						inputPlaceholder={TEXT.researcher_add_resource_placeholder}
						inputValue={resource}
						setInputValue={setResource}
					/>
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_files}</p>

					<Input inputType={'file'} setInputValue={setFiles} />
				</span>

				<span className={style.row}>
					<p className={style.text}>{TEXT.researcher_add_comment}</p>

					<textarea
						value={comment}
						placeholder={TEXT.researcher_add_comment_placeholder}
						onChange={e => setComment(e.target.value)}
						className={classNames({
							[style.input]: true,
						})}
					/>
				</span>

				<Btn type='submit' text={TEXT.researcher_btn_upload} />
			</form>

			{loader && <Loader />}
		</div>
	)
}
