import React from 'react';
import style from './Header.module.scss';
import { Btn } from '../../c_ui/Btn';

export const Header = ({ lang, setLang }) => {
  const handlerChooseEn = () => { setLang('en') }
  const handlerChooseUa = () => { setLang('ua') }

  return (
		<div className={style.header}>
			<Btn text={'en'} handle={handlerChooseEn} isActive={lang === 'en'} />

			<Btn text={'ua'} handle={handlerChooseUa} isActive={lang === 'ua'} />
		</div>
	)
};
